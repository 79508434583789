import React from "react";
import * as THREE from "three";
import './LaptopStand.css';
import 'fontsource-roboto';
import DiscreteSlider from './DiscreteSlider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import wood1 from '../models/black-walnut-full-double.jpg';
import wood2 from '../models/hard-maple-full-double.jpg';
import wood3 from '../models/ash-full-double.jpg';
import finishWalnut from '../images/FinishBaseWalnut.png';
import finishMaple from '../images/FinishBaseMaple.png';
import finishWhiteOak from '../images/FinishBaseWhiteOak.png';
import { CirclePicker, SwatchesPicker } from 'react-color';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
//import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import fileURL from '../models/200928_DeskStandWithShelf.gltf';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as matColor from 'material-colors'
import Header from "./Header";
import Footer from "./Footer";
const { GoogleSpreadsheet } = require('google-spreadsheet');

class LaptopStand extends React.Component {
    constructor(props) {
      super(props);
      //this.lengthSlider = React.createRef();

      this.priceFactor = 8;
      this.cameraDistance = 40;

      this.initialWidth = 20;
      this.initialDepth = 12;
      this.initialHeight = 10;
      this.initialShelfHeight = 4;

      this.state = {
          width: this.initialWidth,
          depth: this.initialDepth,
          height: this.initialHeight,
          shelf: true,
          shelfHeight: this.initialShelfHeight,
          displayColorPicker: false,
          baseColor: '#ff4800',
          customColor: '#999999',
          price: 500,
          wood: 'Walnut',
          finishImage: finishWalnut,
          dimensionScale: 10,
          dimensionRadius: 2,
          frontView: " ",
          sideView: " "
      }

      this.theme = createMuiTheme({
        palette: {
          primary: {
            light: '#484848',
            main: '#212121',
            dark: '#000000',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff8a50',
            main: '#ff5722',
            dark: '#c41c00',
            contrastText: '#000',
          },
        },
      });

      this.doc = null;
      this.orderSheet = null;
      
    }

    componentDidMount() {
        this.doc = new GoogleSpreadsheet('1yqbO0OBI_xHdbU66I0niaezcJ1jqEOH8pWdO2oZ7IDc');
        //this.loadGoogleSheet();
        this.sceneSetup();
        this.addCustomSceneObjects();
        this.updatePrice();
        this.updateDimensions();
        this.startAnimationLoop();
        //window.addEventListener("resize", this.handleWindowResize);
      }

    handleAddToCart = () => {
      //console.log(this.orderSheet.title);
      this.appendSpreadsheet();
    }

    /*
    async loadGoogleSheet() {
      this.doc = new GoogleSpreadsheet('1yqbO0OBI_xHdbU66I0niaezcJ1jqEOH8pWdO2oZ7IDc');
      this.doc.useServiceAccountAuth(require('../orders-291700-4b2f5e54b650.json'));
      await this.doc.loadInfo(); // loads document properties and worksheets
      this.orderSheet = this.doc.sheetsByIndex[0];
    }
    */
   appendSpreadsheet = async () => {
    try {
      await this.doc.useServiceAccountAuth(require('../orders-291700-4b2f5e54b650.json'));
      // loads document properties and worksheets
      await this.doc.loadInfo();
      const nowDate = new Date().toLocaleString();
      const orderSheet = this.doc.sheetsByIndex[0];
      const orderNumber = orderSheet.rowCount;
      await orderSheet.addRow({
        "Order Number": orderNumber,
        "Date": nowDate,
        "Model Number": 'LS-01',
        "Width": this.state.width,
        "Depth": this.state.depth,
        "Height": this.state.height,
        "Shelf": this.state.shelf,
        "Shelf Height": this.state.shelfHeight,
        "Color": this.state.baseColor,
        "Wood": this.state.wood,
        "Quantity": 1 
      });
    } catch (e) {
      console.error('Error: ', e);
    }
  };
    
      // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
      // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
      sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        //const width = this.el.clientWidth;
        //const height = this.el.clientHeight;
    
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xffffff);

        //Lights
        const directionalLight = new THREE.DirectionalLight( 0xffffff, 2 );
        directionalLight.position.set( 1, 1, 1 );
        this.scene.add( directionalLight );

        //Camera
        this.camera = new THREE.PerspectiveCamera(
          50, // fov = field of view
          1, // aspect ratio
          0.1, // near plane
          1000 // far plane
        );
        this.updateCameraPosition();
        
        //Action!
        //this.controls = new OrbitControls(this.camera, this.el);
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        //this.renderer.setSize(width, height);
        this.el.appendChild(this.renderer.domElement); // mount using React ref
        this.renderer.setSize( window.innerWidth, window.innerHeight );
      };
    
      addCustomSceneObjects = () => {
        //Load gltf file
        this.loadedObjectTop = new THREE.Object3D();
        this.loadedObjectBase = new THREE.Object3D();
        this.loadedObjectBaseShelf = new THREE.Object3D();
        this.loadedObjectShelf = new THREE.Object3D();
        this.loadedObjectTopEnd = new THREE.Object3D();
        this.loadedMaterialBase = new THREE.Material();

        var loader = new GLTFLoader();
        loader.crossOrigin = true;
        loader.load(
            // resource URL
            fileURL,
            // called when the resource is loaded
             ( gltf ) => {
                //Load model
                this.scene.add(gltf.scene);

                //Copy Top
                this.loadedObjectTop = gltf.scene.getObjectByName('DeskStandTop');
                //Change starting material
                var tex = new THREE.TextureLoader();
                tex.load(wood1, (newTexture) => {
                  newTexture.encoding = THREE.sRGBEncoding;
                  newTexture.rotation = 1.57;
                  newTexture.flipY = false; // for glTF models.
                  newTexture.wrapS = THREE.MirroredRepeatWrapping;
                  newTexture.wrapT = THREE.MirroredRepeatWrapping;
                  this.loadedObjectTop.material.map = newTexture;
                  this.loadedObjectTop.material.needsUpdate = true;
                  this.loadedObjectTop.material.map.needsUpdate = true;
                });
                this.loadedGeometryTop = new THREE.Geometry().fromBufferGeometry(this.loadedObjectTop.geometry);
                this.newGeometryTop = new THREE.Geometry().fromBufferGeometry(this.loadedObjectTop.geometry);

                //Copy Base
                this.loadedObjectBase = gltf.scene.getObjectByName('DeskStandBase');
                this.loadedGeometryBase = new THREE.Geometry().fromBufferGeometry(this.loadedObjectBase.geometry);
                this.newGeometryBase = new THREE.Geometry().fromBufferGeometry(this.loadedObjectBase.geometry);
                
                //Copy and hide Shelf Base
                this.loadedObjectBaseShelf = gltf.scene.getObjectByName('DeskStandBaseShelf');
                this.loadedGeometryBaseShelf = new THREE.Geometry().fromBufferGeometry(this.loadedObjectBaseShelf.geometry);
                this.newGeometryBaseShelf = new THREE.Geometry().fromBufferGeometry(this.loadedObjectBaseShelf.geometry);
                this.loadedObjectBaseShelf.visible = true;

                //Copy hide Shelf Top
                this.loadedObjectShelf = gltf.scene.getObjectByName('DeskStandShelf');
                this.loadedGeometryShelf = new THREE.Geometry().fromBufferGeometry(this.loadedObjectShelf.geometry);
                this.newGeometryShelf = new THREE.Geometry().fromBufferGeometry(this.loadedObjectShelf.geometry);
                this.loadedObjectShelf.visible = true;

                var tempColor = {r: 1, g: 72/255, b: 0/255};
                this.loadedObjectBase.material.color = tempColor;
                this.loadedObjectBase.material.needsUpdate = true;
                this.loadedObjectBaseShelf.material.color = tempColor;
                this.loadedObjectBaseShelf.material.needsUpdate = true;

                //Copy Material
                //this.loadedMaterialBase = gltf.scene.getObjectByName('MetalMat');
                //console.log(this.loadedMaterialBase);

                /*
                //Use this to parse through the JSON if needed
                gltf.scene.traverse( ( child ) => {
                    if ( child.isMesh ) {

                    }
                });
                */
            },
            // called while loading is progressing
            function ( xhr ) {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function ( error ) {
                console.log( 'An error happened' );
                console.log(error);
            }
 
        );
        
        /*
        const material = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          emissive: 0x072534,
          side: THREE.DoubleSide,
          flatShading: true
        });
        */

        /*
        //Create dimension lines
        var material = new THREE.LineBasicMaterial( { color: 0x555555 } );
        var points = [];

        //Height line
        points.push( new THREE.Vector3( -this.state.width/2-2, 0, this.state.depth/2 ) );
        points.push( new THREE.Vector3( -this.state.width/2-2, this.state.height-1, this.state.depth/2 ) );
        var geometry = new THREE.BufferGeometry().setFromPoints( points );
        this.heightLine = new THREE.Line( geometry, material );
        this.scene.add( this.heightLine );

        //Width line
        points[0] = new THREE.Vector3( -this.state.width/2, 0, this.state.depth/2+1 );
        points[1] = new THREE.Vector3( this.state.width/2, 0, this.state.depth/2+1 );
        geometry = new THREE.BufferGeometry().setFromPoints( points );
        this.widthLine = new THREE.Line( geometry, material );
        this.scene.add( this.widthLine );

        //Depth line
        points[0] = new THREE.Vector3( this.state.width/2+1, 0, this.state.depth/2 );
        points[1] = new THREE.Vector3( this.state.width/2+1, 0, -this.state.depth/2 );
        geometry = new THREE.BufferGeometry().setFromPoints( points );
        this.depthLine = new THREE.Line( geometry, material );
        this.scene.add( this.depthLine );

        //Shelf height line
        points[0] = new THREE.Vector3( this.state.width/2+2, 0, -this.state.depth/2 );
        points[1] = new THREE.Vector3( this.state.width/2+2, this.state.shelfHeight+1, -this.state.depth/2 );
        geometry = new THREE.BufferGeometry().setFromPoints( points );
        this.shelfLine = new THREE.Line( geometry, material );
        this.scene.add( this.shelfLine );
        */
      };
    
      startAnimationLoop = () => {
        this.renderer.render(this.scene, this.camera);
    
        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
      };

      updateCameraPosition() {
        this.camera.position.set(this.state.width/2+this.state.depth/2+this.state.height, this.state.height+5, this.state.width/2+this.state.depth/2+this.state.height);
        this.camera.lookAt(this.state.width/6-1, this.state.height/2+2.5, this.state.depth/6-1);

        var tempScale = 0;
        if(this.state.width>this.state.height){
          tempScale = 200/this.state.width;
        } else {
          tempScale = 200/this.state.height;
        }
        
        var tempRadius = tempScale/5;
        this.setState({
          dimensionScale: tempScale,
          dimensionRadius: tempRadius
        })
      }
    
      /*
      handleWindowResize = () => {
        const width = this.el.clientWidth;
        const height = this.el.clientHeight;
    
        this.renderer.setSize(width, height);
        this.camera.aspect = width / height;
    
        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        this.camera.updateProjectionMatrix();
      };
      */

      handleWidthChange = (newValue) => {
        this.setState({width: newValue});
        this.updateDimensions();
        this.updatePrice();
        this.updateWidth(this.newGeometryTop, this.loadedObjectTop, this.loadedGeometryTop);
        this.updateWidth(this.newGeometryBase, this.loadedObjectBase, this.loadedGeometryBase);
        this.updateWidth(this.newGeometryShelf, this.loadedObjectShelf, this.loadedGeometryShelf);
        this.updateWidth(this.newGeometryBaseShelf, this.loadedObjectBaseShelf, this.loadedGeometryBaseShelf);
      }

      handleDepthChange = (newValue) => {
        this.setState({depth: newValue});
        this.updateDimensions();
        this.updatePrice();
        this.updateDepth(this.newGeometryTop, this.loadedObjectTop, this.loadedGeometryTop);
        this.updateDepth(this.newGeometryBase, this.loadedObjectBase, this.loadedGeometryBase);
        this.updateDepth(this.newGeometryShelf, this.loadedObjectShelf, this.loadedGeometryShelf);
        this.updateDepth(this.newGeometryBaseShelf, this.loadedObjectBaseShelf, this.loadedGeometryBaseShelf);
      }

      handleHeightChange = (newValue) => {
        this.setState({height: newValue});
        this.updateDimensions();
        this.updatePrice();
        this.updateHeight(this.newGeometryTop, this.loadedObjectTop, this.loadedGeometryTop);
        this.updateHeight(this.newGeometryBase, this.loadedObjectBase, this.loadedGeometryBase);
      }

      handleShelfHeightChange = (newValue) => {
        this.setState({shelfHeight: newValue});
        this.updateDimensions();
        this.updateShelfHeight(this.newGeometryShelf, this.loadedObjectShelf, this.loadedGeometryShelf);
        this.updateShelfHeight(this.newGeometryBaseShelf, this.loadedObjectBaseShelf, this.loadedGeometryBaseShelf);
      }

      updateWidth = (newGeometry, loadedObject, loadedGeometry) => {
        for(var i=0; i < newGeometry.vertices.length; i++) {
            var pos = newGeometry.vertices[i];
            if(pos.x < 0) {
            newGeometry.vertices[i].x = loadedGeometry.vertices[i].x+((this.initialWidth-this.state.width)*.5);
            } else if(pos.x > 0) {
            newGeometry.vertices[i].x = loadedGeometry.vertices[i].x-((this.initialWidth-this.state.width)*.5);
            }
        }
        newGeometry.verticesNeedUpdate = true;
        var tempGeometry = new THREE.BufferGeometry().fromGeometry(newGeometry);
        loadedObject.geometry = tempGeometry;
        loadedObject.geometry.attributes.position.needsUpdate = true;
        this.updateCameraPosition();

        /*
        //Update dimension lines
        this.widthLine.geometry.attributes.position.setX(0, -this.state.width/2);
        this.widthLine.geometry.attributes.position.setX(1, this.state.width/2);
        this.widthLine.geometry.attributes.position.needsUpdate = true;

        this.depthLine.geometry.attributes.position.setX(0, this.state.width/2+1);
        this.depthLine.geometry.attributes.position.setX(1, this.state.width/2+1);
        this.depthLine.geometry.attributes.position.needsUpdate = true;

        this.heightLine.geometry.attributes.position.setX(0, -this.state.width/2-2);
        this.heightLine.geometry.attributes.position.setX(1, -this.state.width/2-2);
        this.heightLine.geometry.attributes.position.needsUpdate = true;

        this.shelfLine.geometry.attributes.position.setX(0, this.state.width/2+2);
        this.shelfLine.geometry.attributes.position.setX(1, this.state.width/2+2);
        this.shelfLine.geometry.attributes.position.needsUpdate = true;
        */
      }
      
      updateDepth = (newGeometry, loadedObject, loadedGeometry) => {
        for(var i=0; i < newGeometry.vertices.length; i++) {
            var pos = newGeometry.vertices[i];
            if(pos.z < 0) {
            newGeometry.vertices[i].z = loadedGeometry.vertices[i].z+((this.initialDepth-this.state.depth)*.5);
            } else if(pos.z > 0) {
            newGeometry.vertices[i].z = loadedGeometry.vertices[i].z-((this.initialDepth-this.state.depth)*.5);
            }
        }
        newGeometry.verticesNeedUpdate = true;
        var tempGeometry = new THREE.BufferGeometry().fromGeometry(newGeometry);
        loadedObject.geometry = tempGeometry;
        loadedObject.geometry.attributes.position.needsUpdate = true;
        this.updateCameraPosition();

        /*
        //Update dimension lines
        this.widthLine.geometry.attributes.position.setZ(0, this.state.depth/2+1);
        this.widthLine.geometry.attributes.position.setZ(1, this.state.depth/2+1);
        this.widthLine.geometry.attributes.position.needsUpdate = true;

        this.depthLine.geometry.attributes.position.setZ(0, -this.state.depth/2);
        this.depthLine.geometry.attributes.position.setZ(1, this.state.depth/2);
        this.depthLine.geometry.attributes.position.needsUpdate = true;

        this.heightLine.geometry.attributes.position.setZ(0, this.state.depth/2);
        this.heightLine.geometry.attributes.position.setZ(1, this.state.depth/2);
        this.heightLine.geometry.attributes.position.needsUpdate = true;

        this.shelfLine.geometry.attributes.position.setZ(0, -this.state.depth/2);
        this.shelfLine.geometry.attributes.position.setZ(1, -this.state.depth/2);
        this.shelfLine.geometry.attributes.position.needsUpdate = true;
        */
      }
      
      updateHeight = (newGeometry, loadedObject, loadedGeometry) => {
        for(var i=0; i < newGeometry.vertices.length; i++) {
            var pos = newGeometry.vertices[i].clone();
            loadedObject.localToWorld(pos);
            if(pos.y > 1) {
              newGeometry.vertices[i].y = loadedGeometry.vertices[i].y-(this.initialHeight-this.state.height);
            }
        }
        newGeometry.verticesNeedUpdate = true;
        var tempGeometry = new THREE.BufferGeometry().fromGeometry(newGeometry);
        loadedObject.geometry = tempGeometry;
        loadedObject.geometry.attributes.position.needsUpdate = true;
        this.updateCameraPosition();

        /*
        //update Height dimension line
        this.heightLine.geometry.attributes.position.setY(1, this.state.height-1);
        this.heightLine.geometry.attributes.position.needsUpdate = true;
        */
      }
      
      updateShelfHeight = (newGeometry, loadedObject, loadedGeometry) => {
        for(var i=0; i < newGeometry.vertices.length; i++) {
          newGeometry.vertices[i].y = loadedGeometry.vertices[i].y+(1/8)-(this.initialShelfHeight-this.state.shelfHeight);
        }
        newGeometry.verticesNeedUpdate = true;
        var tempGeometry = new THREE.BufferGeometry().fromGeometry(newGeometry);
        loadedObject.geometry = tempGeometry;
        loadedObject.geometry.attributes.position.needsUpdate = true;
        this.updateCameraPosition();

        /*
        //Update Shelf dimension line
        this.shelfLine.geometry.attributes.position.setY(1, this.state.shelfHeight+1);
        this.shelfLine.geometry.attributes.position.needsUpdate = true;
        */
      }

      handleShelfChange = (event) => {
        var currentState = this.state.shelf;
        this.loadedObjectBaseShelf.visible = !currentState;
        this.loadedObjectShelf.visible = !currentState;
        //this.shelfLine.visible = !currentState;
        this.setState({
          shelf: !currentState
        }, () => {
          this.updateDimensions();
        })
        ;
      }

      handleColorButtonClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleColorButtonClose = () => {
        this.setState({ displayColorPicker: false })
      };

      handleColorChangeComplete = (color, event) => {
        this.setState({ baseColor: color.hex });
        var tempColor = {r: color.rgb.r/255, g: color.rgb.g/255, b: color.rgb.b/255};
        this.loadedObjectBase.material.color = tempColor;
        this.loadedObjectBase.material.needsUpdate = true;
        this.loadedObjectBaseShelf.material.color = tempColor;
        this.loadedObjectBaseShelf.material.needsUpdate = true;
        //this.cube.material.color.setHex("0x"+color.hex.substring(1));
      };

      handleCustomColorChangeComplete = (color, event) => {
        console.log(color);
        this.setState({ 
          customColor: color.hex,
          baseColor: color.hex
        });
        //this.cube.material.color.setHex("0x"+color.hex.substring(1));
      };

      handleWoodChange = (event, choice) => {
        var tex = new THREE.TextureLoader();
        var tempFactor;

        switch(choice) {
          case "walnut":
            tempFactor = 8;
            tex.load(wood1, (newTexture) => {
              newTexture.encoding = THREE.sRGBEncoding;
              newTexture.rotation = 1.57;
              newTexture.flipY = false; // for glTF models.
              //newTexture.repeat = new THREE.Vector2(3, 1);
              newTexture.wrapS = THREE.MirroredRepeatWrapping;
              newTexture.wrapT = THREE.MirroredRepeatWrapping;
              this.loadedObjectTop.material.map = newTexture;
              this.loadedObjectTop.material.needsUpdate = true;
              this.loadedObjectTop.material.map.needsUpdate = true;
            });
            this.setState({
              wood: 'Walnut',
              finishImage: finishWalnut
            });
            break;
          case "oak":
            tempFactor = 6;
            tex.load(wood2, (newTexture) => {
              newTexture.encoding = THREE.sRGBEncoding;
              newTexture.rotation = 1.57;
              newTexture.flipY = false; // for glTF models.
              //newTexture.repeat = new THREE.Vector2(3, 1);
              newTexture.wrapS = THREE.MirroredRepeatWrapping;
              newTexture.wrapT = THREE.MirroredRepeatWrapping;
              this.loadedObjectTop.material.map = newTexture;
              this.loadedObjectTop.material.needsUpdate = true;
              this.loadedObjectTop.material.map.needsUpdate = true;
            });
            this.setState({
              wood: 'Oak',
              finishImage: finishWhiteOak
            });
            break;
          case "maple":
            tempFactor = 7;
            tex.load(wood3, (newTexture) => {
              newTexture.encoding = THREE.sRGBEncoding;
              newTexture.rotation = 1.57;
              newTexture.flipY = false; // for glTF models.
              //newTexture.repeat = new THREE.Vector2(3, 1);
              newTexture.wrapS = THREE.MirroredRepeatWrapping;
              newTexture.wrapT = THREE.MirroredRepeatWrapping;
              this.loadedObjectTop.material.map = newTexture;
              this.loadedObjectTop.material.needsUpdate = true;
              this.loadedObjectTop.material.map.needsUpdate = true;
            });
            this.setState({
              wood: 'Maple',
              finishImage: finishMaple
            });
            break;
          default:
            break;
        }
        //this.setState({priceFactor: tempFactor});
        this.priceFactor = tempFactor;
        this.updatePrice();
      }

      updatePrice() {
        var tempPrice = (200 + 5 * this.state.height) + (this.priceFactor * ( this.state.width + this.state.depth ));
        this.setState({price: tempPrice});
      }

      updateDimensions() {
        const scale = this.state.dimensionScale;
        const radius = this.state.dimensionRadius;
        var tempFrontDimensions = " "
        if(this.state.shelf) {
          tempFrontDimensions=
          "M150,"+(scale*1+40)+
          " h-"+(this.state.width/2*scale-(radius))+
          " a"+radius+","+radius+" 1 0 0 -"+radius+","+radius+
          " v"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 1 0 0 "+radius+","+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 1 0 0 "+radius+",-"+radius+
          " v-"+(scale*(this.state.shelfHeight-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 0 0 1 "+radius+",-"+radius+
          " h"+((scale*this.state.width)-(2*((scale*0.5)+(radius*3))))+
          " a"+radius+","+radius+" 0 0 1 "+radius+","+radius+
          " v"+(scale*(this.state.shelfHeight-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 1 0 0 "+radius+","+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 1 0 0 "+radius+",-"+radius+
          " v-"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 1 0 0 -"+radius+",-"+radius+
          " z"
        } else {
          tempFrontDimensions = 
          "M150,"+(scale*1+40)+
          " h-"+(this.state.width/2*scale-(radius))+
          " a"+radius+","+radius+" 1 0 0 -"+radius+","+radius+
          " v"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 1 0 0 "+radius+","+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 1 0 0 "+radius+",-"+radius+
          " v-"+(scale*(this.state.height-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 0 0 1 "+radius+",-"+radius+
          " h"+((scale*this.state.width)-(2*((scale*0.5)+(radius*3))))+
          " a"+radius+","+radius+" 0 0 1 "+radius+","+radius+
          " v"+(scale*(this.state.height-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 1 0 0 "+radius+","+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 1 0 0 "+radius+",-"+radius+
          " v-"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 1 0 0 -"+radius+",-"+radius+
          " z"
        }

        var tempSideDimensions = 
          "M150,"+(scale*this.state.height+40)+
          " h-"+(this.state.depth/2*scale-(radius))+
          " a"+radius+","+radius+" 0 0 1 -"+radius+",-"+radius+
          " v-"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 0 0 1 "+radius+",-"+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 0 0 1 "+radius+","+radius+
          " v"+(scale*(this.state.height-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 1 0 0 "+radius+","+radius+
          " h"+((scale*this.state.depth)-(2*((scale*0.5)+(radius*3))))+
          " a"+radius+","+radius+" 1 0 0 "+radius+",-"+radius+
          " v-"+(scale*(this.state.height-0.5)-scale-(4*radius))+
          " a"+radius+","+radius+" 0 0 1 "+radius+",-"+radius+
          " h"+(scale*0.5)+
          " a"+radius+","+radius+" 0 0 1 "+radius+","+radius+
          " v"+(this.state.height*scale-(scale)-(radius*2))+
          " a"+radius+","+radius+" 0 0 1 -"+radius+","+radius+
          " z"

        this.setState({
          frontView: tempFrontDimensions,
          sideView: tempSideDimensions
        })
      }

      

      render() {
        return(          
            <div className="THREECanvas">
                <Header
                  title="Laptop Stand"
                />
                <div ref={ref => (this.el = ref)} className="THREEWindow">
                
                </div>
                
                <ThemeProvider theme={this.theme}>
                <div className="THREEControls">
                  
                  <div className="TopControlsSpacer"></div>
                  <h3>What dimensions do you need?</h3>
                  <div className="ControlSection DimensionSliders">
                    <DiscreteSlider 
                        name="Width"
                        startValue={20}
                        minimum={6}
                        maximum={36}
                        onValueChange={this.handleWidthChange}
                        theme={this.theme}
                    />                    
                    <DiscreteSlider 
                        name="Depth"
                        startValue={12}
                        minimum={6}
                        maximum={24}
                        onValueChange={this.handleDepthChange}
                    />
                    <DiscreteSlider 
                        name="Height"
                        startValue={10}
                        minimum={this.state.shelfHeight+4}
                        maximum={24}
                        onValueChange={this.handleHeightChange}
                    />
                  </div>
                  <h3>Do you want a shelf?</h3>
                  <div className="ControlSection ShelfControls">
                    <FormControlLabel 
                      control={<Switch
                        label="Yes"
                        checked={this.state.shelf}
                        onChange={this.handleShelfChange}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />}
                      label="Yes"
                    />
                    
                  <DiscreteSlider 
                    name="Shelf Height"
                    startValue={4}
                    minimum={4}
                    maximum={this.state.height-3}
                    onValueChange={this.handleShelfHeightChange}
                  />
                  </div>
                  <h3>Dimensions</h3>
                  <div className="DimensionChecker">
                    <div className="FrontView">
                      <h4>Front View</h4>
                      {this.state.shelf ?
                      <svg height={this.state.height*this.state.dimensionScale+60} width="300">
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.width/2)}
                          y="40"
                          width={this.state.width*this.state.dimensionScale}
                          height={this.state.dimensionScale*1} 
                          rx={this.state.dimensionRadius}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.width/2)+(2*this.state.dimensionRadius)+this.state.dimensionScale*0.5}
                          y={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          width={this.state.width*this.state.dimensionScale-this.state.dimensionScale-4*this.state.dimensionRadius}
                          height={this.state.dimensionScale*1} 
                          rx={this.state.dimensionRadius}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.width/2)+(this.state.dimensionScale*0.5)+(2*this.state.dimensionRadius)}
                          y={(this.state.dimensionScale+(this.state.dimensionScale*0.5)+(2*this.state.dimensionRadius))+40}
                          width={this.state.width*this.state.dimensionScale-2*((this.state.dimensionScale*0.5)+(2*this.state.dimensionRadius))}
                          height={(this.state.dimensionScale*(this.state.height-this.state.shelfHeight-0.5)-2*this.state.dimensionRadius)} 
                          rx={this.state.dimensionScale/5}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <path 
                          d={this.state.frontView} 
                          fill="none" 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)}
                          y1="10"
                          x2="120"
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)}
                          y1="5"
                          x2={150-(this.state.dimensionScale*this.state.width/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1="180"
                          y1="10"
                          x2={150+(this.state.dimensionScale*this.state.width/2)}
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)}
                          y1="5"
                          x2={150+(this.state.dimensionScale*this.state.width/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x="150" 
                          y="15" 
                          fill="black"
                          textAnchor="middle"
                        >
                          {this.state.width+" in."}
                        </text>
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y2={this.state.dimensionScale*this.state.height/2+25}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-35}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.width/2)-25}
                          y2="40"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y1={this.state.dimensionScale*this.state.height/2+55}
                          x2={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-35}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150-(this.state.dimensionScale*this.state.width/2)-25}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150-(this.state.dimensionScale*this.state.width/2)-30} 
                          y={this.state.dimensionScale*this.state.height/2+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.height+" in."}
                        </text>
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)+30}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          x2={150+(this.state.dimensionScale*this.state.width/2)+30}
                          y2={this.state.dimensionScale*(this.state.height-(this.state.shelfHeight/2))+25} 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)+25}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          x2={150+(this.state.dimensionScale*this.state.width/2)+35}
                          y2={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)+30}
                          y1={this.state.dimensionScale*(this.state.height-(this.state.shelfHeight/2))+55} 
                          x2={150+(this.state.dimensionScale*this.state.width/2)+30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)+25}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150+(this.state.dimensionScale*this.state.width/2)+35}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150+(this.state.dimensionScale*this.state.width/2)+30} 
                          y={this.state.dimensionScale*(this.state.height-this.state.shelfHeight/2)+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.shelfHeight+" in."}
                        </text>
                      </svg> 
                      :
                      <svg height={this.state.height*this.state.dimensionScale+60} width="300">
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.width/2)}
                          y="40"
                          width={this.state.width*this.state.dimensionScale}
                          height={this.state.dimensionScale*1} 
                          rx={this.state.dimensionRadius}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        
                        <path 
                          d={this.state.frontView} 
                          fill="none" 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)}
                          y1="10"
                          x2="120"
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)}
                          y1="5"
                          x2={150-(this.state.dimensionScale*this.state.width/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1="180"
                          y1="10"
                          x2={150+(this.state.dimensionScale*this.state.width/2)}
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.width/2)}
                          y1="5"
                          x2={150+(this.state.dimensionScale*this.state.width/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x="150" 
                          y="15" 
                          fill="black"
                          textAnchor="middle"
                        >
                          {this.state.width+" in."}
                        </text>
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y2={this.state.dimensionScale*this.state.height/2+25}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-35}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.width/2)-25}
                          y2="40"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y1={this.state.dimensionScale*this.state.height/2+55}
                          x2={150-(this.state.dimensionScale*this.state.width/2)-30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.width/2)-35}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150-(this.state.dimensionScale*this.state.width/2)-25}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150-(this.state.dimensionScale*this.state.width/2)-30} 
                          y={this.state.dimensionScale*this.state.height/2+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.height+" in."}
                        </text>
                      </svg>
                    }
                    </div>
                    <div className="SideView">
                      <h4>Side View</h4>
                      {this.state.shelf ? <svg height={this.state.height*this.state.dimensionScale+60} width="300">
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.depth/2)}
                          y="40"
                          width={this.state.depth*this.state.dimensionScale}
                          height={this.state.dimensionScale*1} 
                          rx={this.state.dimensionRadius}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)+(2*this.state.dimensionRadius)+this.state.dimensionScale*0.5}
                          y1={34+this.state.dimensionScale*(1+0.5)+2*(this.state.dimensionRadius)}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)-(2*this.state.dimensionRadius)-this.state.dimensionScale*0.5}
                          y2={34+this.state.dimensionScale*(1+0.5)+2*(this.state.dimensionRadius)}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line 
                          x1={150-(this.state.dimensionScale*this.state.depth/2)+(2*this.state.dimensionRadius)+this.state.dimensionScale*0.5}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)-(2*this.state.dimensionRadius)-this.state.dimensionScale*0.5}
                          y2={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <line 
                          x1={150-(this.state.dimensionScale*this.state.depth/2)+(2*this.state.dimensionRadius)+this.state.dimensionScale*0.5}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight+1)+40}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)-(2*this.state.dimensionRadius)-this.state.dimensionScale*0.5}
                          y2={this.state.dimensionScale*(this.state.height-this.state.shelfHeight+1)+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <path 
                          d={this.state.sideView} 
                          fill="none" 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)}
                          y1="10"
                          x2="120"
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)}
                          y1="5"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1="180"
                          y1="10"
                          x2={150+(this.state.dimensionScale*this.state.depth/2)}
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)}
                          y1="5"
                          x2={150+(this.state.dimensionScale*this.state.depth/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x="150" 
                          y="15" 
                          fill="black"
                          textAnchor="middle"
                        >
                          {this.state.depth+" in."}
                        </text>
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y2={this.state.dimensionScale*this.state.height/2+25}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-35}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-25}
                          y2="40"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y1={this.state.dimensionScale*this.state.height/2+55}
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-35}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-25}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150-(this.state.dimensionScale*this.state.depth/2)-30} 
                          y={this.state.dimensionScale*this.state.height/2+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.height+" in."}
                        </text>
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)+30}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)+30}
                          y2={this.state.dimensionScale*(this.state.height-(this.state.shelfHeight/2))+25} 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)+25}
                          y1={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)+35}
                          y2={this.state.dimensionScale*(this.state.height-this.state.shelfHeight)+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)+30}
                          y1={this.state.dimensionScale*(this.state.height-(this.state.shelfHeight/2))+55} 
                          x2={150+(this.state.dimensionScale*this.state.depth/2)+30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)+25}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)+35}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150+(this.state.dimensionScale*this.state.depth/2)+30} 
                          y={this.state.dimensionScale*(this.state.height-this.state.shelfHeight/2)+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.shelfHeight+" in."}
                        </text>
                      </svg> 
                      : 
                      <svg height={this.state.height*this.state.dimensionScale+60} width="300">
                        <rect 
                          x={150-(this.state.dimensionScale*this.state.depth/2)}
                          y="40"
                          width={this.state.depth*this.state.dimensionScale}
                          height={this.state.dimensionScale*1} 
                          rx={this.state.dimensionRadius}
                          style={{stroke: `black`, fill:`none`,strokeWidth:2}}
                        />
                        <path 
                          d={this.state.sideView} 
                          fill="none" 
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)+(2*this.state.dimensionRadius)+this.state.dimensionScale*0.5}
                          y1={34+this.state.dimensionScale*(1+0.5)+2*(this.state.dimensionRadius)}
                          x2={150+(this.state.dimensionScale*this.state.depth/2)-(2*this.state.dimensionRadius)-this.state.dimensionScale*0.5}
                          y2={34+this.state.dimensionScale*(1+0.5)+2*(this.state.dimensionRadius)}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)}
                          y1="10"
                          x2="120"
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)}
                          y1="5"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1="180"
                          y1="10"
                          x2={150+(this.state.dimensionScale*this.state.depth/2)}
                          y2="10"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150+(this.state.dimensionScale*this.state.depth/2)}
                          y1="5"
                          x2={150+(this.state.dimensionScale*this.state.depth/2)}
                          y2="15"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x="150" 
                          y="15" 
                          fill="black"
                          textAnchor="middle"
                        >
                          {this.state.depth+" in."}
                        </text>
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y2={this.state.dimensionScale*this.state.height/2+25}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-35}
                          y1="40"
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-25}
                          y2="40"
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y1={this.state.dimensionScale*this.state.height/2+55}
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-30}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <line
                          x1={150-(this.state.dimensionScale*this.state.depth/2)-35}
                          y1={this.state.dimensionScale*this.state.height+40}
                          x2={150-(this.state.dimensionScale*this.state.depth/2)-25}
                          y2={this.state.dimensionScale*this.state.height+40}
                          style={{stroke: `black`, fill:`none`,strokeWidth:1}}
                        />
                        <text 
                          x={150-(this.state.dimensionScale*this.state.depth/2)-30} 
                          y={this.state.dimensionScale*this.state.height/2+45} 
                          fill="black"
                          textAnchor="middle"
                        >{this.state.height+" in."}
                        </text>
                      </svg>}
                      
                    </div>
                    
                  </div>
                  <h3>Choose a type of wood</h3>
                  <div className="WoodChooser">  
                      <div className="WoodButtons">
                          <FormControl component="fieldset">
                              <RadioGroup row defaultValue="walnut" onChange={this.handleWoodChange}>
                                  <FormControlLabel
                                      value="walnut"
                                      control={<Radio color="primary" />}
                                      label="Walnut"
                                      labelPlacement="top"
                                  />
                                  <FormControlLabel
                                      value="oak"
                                      control={<Radio color="primary" />}
                                      label="Oak"
                                      labelPlacement="top"
                                  />
                                  <FormControlLabel
                                      value="maple"
                                      control={<Radio color="primary" />}
                                      label="Maple"
                                      labelPlacement="top"
                                  />
                              </RadioGroup>
                          </FormControl>
                      </div>
                    </div>

                    <div className="CheckFinishes">
                      <div className="FinishMockupBase" style={{backgroundImage: `url(${this.state.finishImage})`}}></div>
                      <div className="FinishMockupMask" style={{backgroundColor:this.state.baseColor}}></div>
                      <div className="FinishMockupMask2" style={{backgroundColor:this.state.baseColor}}></div>
                    </div>
                    <h3>Pick a powder coat color</h3>
                    <div className=".ControlSection ColorPicker">
                        <CirclePicker 
                          color={this.state.baseColor}
                          onChangeComplete={ this.handleColorChangeComplete }
                          colors={["#ff4800", "#3B5F39", "#1F5CAF", this.state.customColor]}
                        />
                        
                          <TextField 
                            disabled
                            className="CustomColorField"
                            onClick={ this.handleColorButtonClick }
                            value={this.state.customColor}
                            helperText="Custom Color"
                          />
                        
                      { this.state.displayColorPicker ? 
                        <div className="Popover">
                          <div className="Cover" onClick={ this.handleColorButtonClose }/>
                            <SwatchesPicker 
                              className="CustomColorPicker"
                              width="222px"
                              color={this.state.customColor}
                              colors={[
                                [matColor.red['900'], matColor.red['700'], matColor.red['500'], matColor.red['300'], matColor.red['100']],
                                [matColor.pink['900'], matColor.pink['700'], matColor.pink['500'], matColor.pink['300'], matColor.pink['100']],
                                [matColor.purple['900'], matColor.purple['700'], matColor.purple['500'], matColor.purple['300'], matColor.purple['100']],
                                [matColor.deepPurple['900'], matColor.deepPurple['700'], matColor.deepPurple['500'], matColor.deepPurple['300'], matColor.deepPurple['100']],
                                [matColor.indigo['900'], matColor.indigo['700'], matColor.indigo['500'], matColor.indigo['300'], matColor.indigo['100']],
                                [matColor.blue['900'], matColor.blue['700'], matColor.blue['500'], matColor.blue['300'], matColor.blue['100']],
                                [matColor.lightBlue['900'], matColor.lightBlue['700'], matColor.lightBlue['500'], matColor.lightBlue['300'], matColor.lightBlue['100']],
                                [matColor.cyan['900'], matColor.cyan['700'], matColor.cyan['500'], matColor.cyan['300'], matColor.cyan['100']],
                                [matColor.teal['900'], matColor.teal['700'], matColor.teal['500'], matColor.teal['300'], matColor.teal['100']],
                                ['#194D33', matColor.green['700'], matColor.green['500'], matColor.green['300'], matColor.green['100']],
                                [matColor.lightGreen['900'], matColor.lightGreen['700'], matColor.lightGreen['500'], matColor.lightGreen['300'], matColor.lightGreen['100']],
                                [matColor.lime['900'], matColor.lime['700'], matColor.lime['500'], matColor.lime['300'], matColor.lime['100']],
                                [matColor.yellow['900'], matColor.yellow['700'], matColor.yellow['500'], matColor.yellow['300'], matColor.yellow['100']],
                                [matColor.amber['900'], matColor.amber['700'], matColor.amber['500'], matColor.amber['300'], matColor.amber['100']],
                                [matColor.orange['900'], matColor.orange['700'], matColor.orange['500'], matColor.orange['300'], matColor.orange['100']],
                                [matColor.deepOrange['900'], matColor.deepOrange['700'], matColor.deepOrange['500'], matColor.deepOrange['300'], matColor.deepOrange['100']],
                                [matColor.brown['900'], matColor.brown['700'], matColor.brown['500'], matColor.brown['300'], matColor.brown['100']],
                                [matColor.blueGrey['900'], matColor.blueGrey['700'], matColor.blueGrey['500'], matColor.blueGrey['300'], matColor.blueGrey['100']],
                                ['#000000', '#525252', '#969696', '#D9D9D9', '#FFFFFF'],
                              ]}
                              onChangeComplete={ this.handleCustomColorChangeComplete }
                            />
                        </div> : null }
                    </div>
                    <h3>Price with standard shipping <br></br>(taxes not included)</h3>
                    <div className="Price">
                      <h1>{"$"+this.state.price}</h1>
                    </div>
                    
                    <div className="CartButton">
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={this.handleAddToCart}
                      >
                        Add to Cart
                      </Button>
                    </div>
                    <div className="FAQ">
                      <h3>Frequently Asked Questions</h3>
                    </div>
                    <div className="BottomSpacer"></div>
                </div>
                <Footer />
                </ThemeProvider>
            </div>
        )
      }
}

export default LaptopStand;
//const rootElement = document.getElementById("root");
//ReactDOM.render(<Modulo />, rootElement);

/*
import woodThumb1 from '../models/walnut-thumb.png';
import woodThumb2 from '../models/maple-thumb.png';
import woodThumb3 from '../models/ash-thumb.png';

<div className="WoodThumbs">
    <img src={woodThumb1} alt="Walnut" width='30px'></img>
    <img src={woodThumb2} alt="Cherry" width='30px'></img>
    <img src={woodThumb3} alt="Maple" width='30px'></img>
</div>
*/