import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from '../images/Wood-and-Welds-Logomark-White.svg'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

class Header extends React.Component {
    render() {
        return(
            <header className="App-header">
                <Link 
                    to="/"
                    className="MenuButton"
                >
                    <img 
                        src={logo}
                        width="30px"
                        alt="Menu"
                    />
                </Link>
                <div className="HeaderTitle">
                    {this.props.title}
                </div>
                <Link
                    className="CartButton"
                    to="/"
                >
                    <ShoppingCartOutlinedIcon
                        fontSize="large"
                        style={{fill: "white"}}
                    />
                </Link>
            </header>
        )
    }
}

export default Header