import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return(
            <div className="Footer">
                    <img 
                        width='300px'
                        src={require('../images/Wood-and-Welds-Logo-Black.svg')} 
                        alt="Wood & Welds Logo"
                    />
                    <div className="FooterLinks">
                        <Link to="/LaptopStand">About</Link>
                        <Link to="/LaptopStand">Jobs</Link>
                        <Link to="/LaptopStand">Contact</Link>
                    </div>
                    <div className="Copyright">© COPYRIGHT 2020 BY WOOD & WELDS LLC</div>
                </div>
        )
    }
}

export default Footer