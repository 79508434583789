import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';

class Home extends React.Component {
    constructor(props) {
        super(props);
    
        this.theme = createMuiTheme({
          palette: {
            primary: {
              light: '#484848',
              main: '#212121',
              dark: '#000000',
              contrastText: '#fff',
            },
            secondary: {
              light: '#ff8a50',
              main: '#ff5722',
              dark: '#c41c00',
              contrastText: '#000',
            },
          },
        });
        
      };

    render() {
        return(
                
        <div className="HomePage">
            <ThemeProvider theme={this.theme}>
                <Header 
                    title="Wood & Welds"
                />
                <div className="HomeSection1">
                    <h1 className="BannerHeading White">You customize it.</h1>
                    <img 
                        src={require('../images/PhoneScreen@2x.png')} 
                        alt='Wood-and-welds-phone-screen'
                        width='50%'
                    />
                </div>
                <div className="HomeSection2">
                    <h1 className="BannerHeading Black">We build it.</h1>
                </div>
                <div className="HomeDivider">Features</div>
                <div className="DetailSection DetailOne">
                    <h2 className="DetailHeading">Powder coated steel</h2>
                </div>
                <div className="DetailSection DetailTwo">
                    <h2 className="DetailHeading">Solid wood</h2>
                </div>
                <div className="DetailSection DetailThree">
                    <h2 className="DetailHeading">Fully assembled</h2>
                </div>
                <div className="HomeDivider">Products</div>
                <div className="ProductSection ProductOne">
                    <h3>Side Table</h3>
                    <div className="CustomizeButton">
                        <div className="LinkContainer">
                            <Link to="/LaptopStand">Customize</Link>
                        </div>
                    </div>
                </div>
                <div className="ProductSection ProductTwo">
                    <h3>Laptop Stand</h3>
                    <div className="CustomizeButton">
                        <div className="LinkContainer">
                            <Link to="/LaptopStand">Customize</Link>
                        </div>
                    </div>
                </div>
                <div className="ProductSection ProductThree">
                    <h3>Writing Desk</h3>
                    <div className="CustomizeButton">
                        <div className="LinkContainer">
                            <Link to="/LaptopStand">Customize</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </ThemeProvider> 
        </div>
        );
    }
}

export default Home;